import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Classes from "./index.module.css";
import Emoji from "../Emoji";

// Images
// import i1 from "../../assets/1.jpg";
// import i2 from "../../assets/2.jpg";
import i3 from "../../assets/3.jpg";
import i4 from "../../assets/4.jpg";
import i5 from "../../assets/5.jpg";
import i6 from "../../assets/6.jpg";
import i7 from "../../assets/7.jpg";
import i8 from "../../assets/8.jpg";
import i9 from "../../assets/9.jpg";
import i10 from "../../assets/10.jpg";
import i11 from "../../assets/11.jpg";
import i12 from "../../assets/12.jpg";
import i13 from "../../assets/13.jpg";
import i14 from "../../assets/14.jpg";
import i15 from "../../assets/15.jpg";
import i16 from "../../assets/16.jpg";

function Album() {
  return (
    <div className={Classes.container}>
      <div className={Classes.content}>
        Take a look at the most beautiful and prettiest girl{" "}
        <Emoji emoticon="🙈" lable="shy emoji" />
        <Emoji emoticon="💜" lable="purple heart" />
        <Emoji emoticon="😍" lable="eye popping out heart" />
      </div>
      <div className={Classes.frameContainer}>
        <div className={Classes.frame}>
          <ImageGallery
            showBullets
            showThumbnails={false}
            items={[
              // { original: i1, thumbnail: i1 },
              // { original: i2, thumbnail: i2 },
              { original: i3, thumbnail: i3 },
              { original: i4, thumbnail: i4 },
              { original: i5, thumbnail: i5 },
              { original: i6, thumbnail: i6 },
              { original: i7, thumbnail: i7 },
              { original: i8, thumbnail: i8 },
              { original: i9, thumbnail: i9 },
              { original: i10, thumbnail: i10 },
              { original: i11, thumbnail: i11 },
              { original: i12, thumbnail: i12 },
              { original: i13, thumbnail: i13 },
              { original: i14, thumbnail: i14 },
              { original: i15, thumbnail: i15 },
              { original: i16, thumbnail: i16 },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Album;
