import React from "react";
import Emoji from "../../Emoji";
import Classes from "./index.module.css";

function Letter() {
  return (
    <div className={Classes.container}>
      <h1 className={Classes.h1}>A letter to you</h1>
      <p className={Classes.letter}>
        First of all, a very very very very very very{" "}
        <strong>Happy Birthday Sweetheart!!</strong>{" "}
        <Emoji emoticon="​🎂​🥳​📅​" />
        <br />
        <br />
        I know, I know that you said that these long letters no longer makes
        sense to you and short birthday wishes from the people that actually
        love and care about you is all what matters, BUT I wanted to say a lot
        of things to kya karta? <Emoji emoticon="🥺" /> So, you are 21 year old
        now <Emoji emoticon="❤️😛🥳" />, bs aise hi please keep growing old with
        me <Emoji emoticon="❤️️" />. This day and this month is extremely
        beautiful sweetheart, please don't even for once consider it "bad", you
        have genuinely transformed me as a person, made me more human and turned
        me into a legit baby <Emoji emoticon="😂" /> so I have a lot of reasons
        to be thankful to this day. This is the day which brought this cute
        little baby to this world to be with me <Emoji emoticon="🙂" />. I
        seriously don't know what I'd do without you, so yeah best girlfriend
        ever <Emoji emoticon="😂😛" />.
        <br />
        <br />
        This year hasn't been kind to anyone and we are no exception to it but
        if we are together then we can be sure that everything will eventually
        fall in place. Never lose confidence in yourself sweetheart, you are
        capable of doing everything that you want and I am pretty sure that you
        will get everything that you want <Emoji emoticon="❤️️" />. Just make
        sure that this little baby works ehr ass off for the future.
        <br />
        Trust me the future us must be very happy just like we always are (other
        than the time when you fight obviously <Emoji emoticon="😕😂" />
        ).
        <br />
        <br />
        Admittedly this isn't the best possible gift for you and you deserve far
        far better gift but unfortunately couldn't do much this time, will
        surely try something better next time. <Emoji emoticon="🥺😚😚😚😚" />.
      </p>
    </div>
  );
}

export default Letter;
