import React from "react";
import "./App.css";
import Countdown, { CountdownRenderProps } from "react-countdown";
import Landing from "./page/landing";
import Emoji from "./components/Emoji";

const BIRTHDAY = new Date("09/30/2020");

function renderer({
  hours,
  minutes,
  seconds,
  completed,
  days,
}: CountdownRenderProps): React.ReactNode {
  if (completed) return <Landing />;

  return (
    <div className="decor">
      <div />
      <div className="countdown">
        Only {days} days {hours} hours {minutes} minutes and {seconds} seconds
        left!!
        <div>
          <Emoji emoticon="🥳" lable="celebrate" />
        </div>
      </div>
      <div className="note">
        Come back on the special day!!{" "}
        <div>
          <Emoji emoticon="🎊🎁🎈💃🕺" lable="celebrate" />
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <Countdown date={BIRTHDAY} renderer={renderer} />
    </div>
  );
}

export default App;
