import React from "react";

function Emoji({
  emoticon,
  lable = "emoji",
}: {
  emoticon: string;
  lable?: string;
}) {
  return (
    <span aria-label={lable} role="img">
      {emoticon}
    </span>
  );
}

export default Emoji;
