import React from "react";
import art from "../../../assets/art.jpg";
import classes from "./index.module.css";
import Emoji from "../../Emoji";

function Art() {
  return (
    <div className={classes.container}>
      <img src={art} alt="art" className={classes.img} />
      <div className={classes.text}>
        Yeah, I drew this. I have written random things in it. Give it a read. I
        hope you like it <Emoji emoticon="🥺" />
      </div>
    </div>
  );
}

export default Art;
