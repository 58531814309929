import React, { useState, Fragment, useEffect } from "react";
import Classes from "./index.module.css";
import Emoji from "../Emoji";
import Letter from "./Letter";
import Art from "./Art";
import AtoZ from "./AtoZ";
import Songs from "./Songs";

function popup(item: string) {
  switch (item) {
    case "letter":
      return <Letter />;
    case "art":
      return <Art />;
    case "atoz":
      return <AtoZ />;
    case "songs":
      return <Songs />;
    default:
      return <Fragment />;
  }
}

function Gifts() {
  const [type, setType] = useState("");

  useEffect(() => {
    const execute = (e: KeyboardEvent) => {
      if (e.keyCode === 27) setType("");
    };

    document.addEventListener("keydown", execute);

    return () => {
      document.removeEventListener("keydown", execute);
    };
  }, []);

  return (
    <div className={Classes.container}>
      <h1 className={Classes.h1}>
        Here are a few things that I could do for you
      </h1>
      <div className={Classes.gifts}>
        <div className={Classes.gift} onClick={() => setType("letter")}>
          <Emoji emoticon="🎁" lable="first gift" />
          <div className={Classes.content}>Click To Open Gift</div>
        </div>
        <div className={Classes.gift} onClick={() => setType("songs")}>
          <Emoji emoticon="🎁" lable="second gift" />
          <div className={Classes.content}>Click To Open Gift</div>
        </div>
        <div className={Classes.gift} onClick={() => setType("art")}>
          <Emoji emoticon="🎁" lable="third gift" />
          <div className={Classes.content}>Click To Open Gift</div>
        </div>
        <div className={Classes.gift} onClick={() => setType("atoz")}>
          <Emoji emoticon="🎁" lable="fourth gift" />
          <div className={Classes.content}>Click To Open Gift</div>
        </div>
      </div>
      <div className={`${Classes.popup} ${type ? "" : Classes.hide}`}>
        <div className={Classes.close} onClick={() => setType("")}>
          &#x2715;
        </div>
        {popup(type)}
      </div>
      <div></div>
    </div>
  );
}

export default Gifts;
