import React from "react";
import Confetti from "react-confetti";
import Classes from "./index.module.css";

function Welcome() {
  return (
    <div className={Classes.top}>
      <Confetti
        height={window.innerHeight}
        width={window.innerWidth - 20}
        initialVelocityY={8}
        gravity={0.08}
        tweenDuration={10000}
        recycle={true}
      />
      <div className={Classes.content}>
        Happy Birthday Love{" "}
        <span aria-label="love" role="img" className={Classes.emoji}>
          ❤️
        </span>
        <span aria-label="celebrate" role="img" className={Classes.emoji}>
          🥳
        </span>
        !
      </div>
    </div>
  );
}

export default Welcome;
