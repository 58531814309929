import React from "react";
import Welcome from "../../components/Welcome";
import Album from "../../components/Album";
import Gifts from "../../components/Gifts";

export default function Landing() {
  return (
    <div>
      <Welcome />
      <Album />
      <Gifts />
    </div>
  );
}
