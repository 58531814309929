import React from "react";
import Emoji from "../../Emoji";
import classes from "./index.module.css";

function Songs() {
  return (
    <div className={classes.container}>
      <div className={classes.head}>
        This is the best I can sing, please don't laugh at me{" "}
        <Emoji emoticon="🥺" />
      </div>
      <audio src={require("../../../assets/song.mp3")} controls></audio>
    </div>
  );
}

export default Songs;
