import React from "react";
import classes from "./index.module.css";

export interface IData {
  title: string;
  content: string;
}

const data: IData[] = [
  {
    title: "A",
    content: "Amazing",
  },
  {
    title: "B",
    content: "Beautiful",
  },
  {
    title: "C",
    content: "Cutest person on planet since 1999",
  },
  {
    title: "D",
    content: "Dexterous",
  },
  {
    title: "E",
    content: "Ebullient",
  },
  {
    title: "F",
    content: "Family :)",
  },
  {
    title: "G",
    content: "Gorgeous",
  },
  {
    title: "H",
    content: "Home :)",
  },
  {
    title: "I",
    content: "Intelligent usually idiot sometimes",
  },
  {
    title: "J",
    content: "Joker sometimes :P",
  },
  {
    title: "K",
    content: "Kid",
  },
  {
    title: "L",
    content: "Love",
  },
  {
    title: "M",
    content: "Marriage material LOL",
  },
  {
    title: "N",
    content: "Natural",
  },
  {
    title: "O",
    content: "Open-hearted",
  },
  {
    title: "P",
    content: "PERFECT",
  },
  {
    title: "Q",
    content: "Queenly",
  },
  {
    title: "R",
    content: "Rare",
  },
  {
    title: "S",
    content: "Super Caring",
  },
  {
    title: "T",
    content: "T-Rex (Dangerous) :P",
  },
  {
    title: "U",
    content: "Utkarsh (Yes, me)",
  },
  {
    title: "V",
    content: "Very Irritating sometimes :/",
  },
  {
    title: "W",
    content: "World",
  },
  {
    title: "X",
    content: "Xenial",
  },
  {
    title: "Y",
    content: "Yare obviously",
  },
  {
    title: "Z",
    content: "Zealous",
  },
];

function FlipCard({ title, content }: IData) {
  return (
    <div className={classes.card}>
      <div className={classes.cardInner}>
        <div className={classes.cardFront}>{title}</div>
        <div className={classes.cardBack}>{content}</div>
      </div>
    </div>
  );
}

function AtoZ() {
  return (
    <div className={classes.container}>
      <h1 className={classes.h1}>
        26 things that you are to me <br />
        (I know, I know it's dumb but still)
      </h1>
      <div className={classes.cards}>
        {data.map((d, i) => (
          <FlipCard {...d} key={`card-${i}`} />
        ))}
      </div>
    </div>
  );
}

export default AtoZ;
